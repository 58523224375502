import { tenantAuthLogout } from "@ovrture/react-redux";

export const insertLogoutMenu = (dispatch) => {
  let ul =
    document.querySelector("ul.menu-list") ||
    document.querySelector("ul.dropdown-menu");
  if (ul) {
    ul.insertAdjacentHTML(
      "beforeend",
      "<li role='presentation'><a class='menu-item-logout' role='menuitem' tabindex='0'>Sign Out</a></li>"
    );
    ul?.querySelector(".menu-item-logout")?.addEventListener("click", (e) =>
      clickLogoutMenu(e, dispatch)
    );
  }
};

export const getLoginUrl = () => {
  return (
    JSON?.parse(localStorage?.getItem("tenant_user"))?.loginUrl ||
    window.location.origin
  );
};

const clickLogoutMenu = (e, dispatch) => {
  let logoutUrl = getLoginUrl();
  dispatch(tenantAuthLogout());
  window.location.href = logoutUrl;
};
